import React from 'react'
import theme from 'design'
import { makeIcon } from 'design/components/Icons/Icons'
import { TwoColorIconDefinition } from 'design/components/Icons/types'

export const def: TwoColorIconDefinition = (
  colors = { base: 'currentColor', accent: 'currentColor' }
) => {
  return {
    id: 'lock',
    name: 'Lock',
    category: 'misc',
    path: (
      <g fill="none" fillRule="evenodd">
        <path
          d="M-417.5-15.5h448v47h-448z"
          fill="transparent"
          stroke={colors.base}
        />
        <g fill={colors.accent}>
          <path d="M10 5a3 3 0 10-6 0v2H2V5a5 5 0 1110 0v2h-2z" />
          <rect height="9" rx="1" width="14" y="7" />
        </g>
      </g>
    ),
    viewBox: '0 0 14 16',
  }
}

export const IconLock = makeIcon(
  def({ base: 'currentColor', accent: theme.colors.bb.granite })
)
export const IconLockMono = makeIcon(def())
export default IconLock
